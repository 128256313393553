import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { confirmParishUser } from '../../../../data/authServises.js'
const confirmPUser = () => {
    const { token } = useParams();
    useEffect(() => {
        const confirmUser = async () => {
            const { res: message } = await confirmParishUser(token);
            if (message) window.location = '/login';
        }
        confirmUser();
    }, []);
    return (
        <div>confirmParishUser</div>
    )
}

export default confirmPUser