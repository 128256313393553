import React, { Component, useEffect, useState } from 'react'
import InnerHTML from "dangerously-set-html-content";
import { getCentImiryango, getCentral, getDiocese, getParish, getParishCentral } from 'src/data/groupsData';
import { CListGroup } from '@coreui/react';


const user = JSON.parse(localStorage.getItem("currentUserData"));

class Homepage extends Component {

  languagesTranslatedComponent = `
  <span>
  <div class="translate" id="google_translate_element"></div>
  <script type="text/javascript">
    function googleTranslateElementInit() {
      new google.translate.TranslateElement(
        { pageLanguage: 'en', includedLanguages: 'en,fr,rw' },
        'google_translate_element',
      )
    }
  </script>
  <script
    type="text/javascript"
    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  ></script>
  </span>
  `;

  constructor(props) {
    super(props);
    this.state = {
      centrals: [],
      imiryango: [],
      departs: [],
      isExpanded:false
    };
  }

  Diocese0ne = [];
  DioceseTwo = [];
  Centrals = [];

  
  handleClick = (e) => {
    if(e.target){
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    })
  
    );
  }
  }

  componentDidMount() {
    // const { data: parishes } = await getParish();
    // this.setState(({parishes}))
    // console.log(this.state.parishes)

    getParish().then(
      (res) => {
        const { data: parishes } = res;
        //  console.log(parishes)
        this.Diocese0ne = parishes.filter(parish => parish.deocese_id === 1);
        this.DioceseTwo = parishes.filter(parish => parish.deocese_id === 2)
        //  console.log(this.DioceseTwo)
        this.setState({ parishes })
        //console.log(parishes)
        getCentral().then((res) => {
          this.Centrals = res.data;
          this.setState({ centrals: res.data })
          console.log(this.state.centrals)
        });
      }
    );
    getCentImiryango().then((res) => {
      console.log(res.data);
      this.setState({ imiryango: res.data })
    })
  }


  render() {
    return (
      <div className="homepage bg-[#000] overflow-x-hidden">
        <div className="home-header flex justify-between items-center p-3">
          <div className='logo flex gap-2.5 items-center z-20'>
            <img src={require("../../assets/images/logo/caritas_logo.png")} className="w-[80px] h-[80px] object-cover" />
            <span className="logo-text text-[#5c1314] text-[25px]">CARITAS RWANDA (CYANGUGU-GIKONGORO) </span>
          </div>
          <div className="header-buttons flex justify-between items-center gap-5">
            {/* <InnerHTML  html={languagesTranslatedComponent}/> */}
            <a href="/#diocese-and-parish" className="text-[#20A8D8] text-[22px] p-1 bg-[transparent] border-2 border-[#fff]">Dioceses and Parishes</a>


            {user ? (
              <button className="d-button bg-[#5c1314] text-white rounded-[8px] py-2 px-5 h-[fit-content]" onClick={() => {
                let pathTogo;
                if (user) {
                  if (user.is_superuser) {
                    pathTogo = "/dashboard";
                  }
                  if (user.dioceseUser) {
                    if (user.title === "director_of_caritas") {
                      pathTogo = "/dashboard";
                    }
                    if (user.title === "it_officer") {
                      pathTogo = "/dashboard";
                    }
                    if (
                      user.title === "health_coordinator" ||
                      user.title === "development_coordinator" ||
                      user.title === "social_coordinator"
                    ) {
                      pathTogo = "/Ddashboard";
                    }
                  }
                  if (user.parishUser) {
                    pathTogo = "/Pdashboard";
                  }
                }
                window.location = pathTogo;
              }}>
                DashBoard
              </button>
            ) : (<button className="login-button bg-[#5c1314] text-white rounded-[8px] py-2 px-5 h-[fit-content]" onClick={() => {
              window.location = "/login";
            }}>
              Login
            </button>)}

          </div>

        </div>

        <div className="intro-text text-[#5c1314] text-center text-[30px] my-5 font-[600] ">WELCOME TO  THE CARITAS PROJECT MANAGEMENT SYSTEM</div>

        <div className="home-desc-board bg-black opacity-50 w-[500px] p-5 my-0 mx-auto flex flex-col justify-center items-center text-white gap-2.5">
          <img src={require("../../assets/images/logo/caritas_logo.png")} className="w-[60px] h-[60px]" />
          <p className="text-[#fff] z-10"> CPMS(Caritas Project Management System) is a system to manage projects and activities which are undertaken by CYANGUGU and GIKONGORO dioceses and parishes.</p>
        </div>
        <div className="diocese-and-parish bg-[#111111e0] w-full mx-0" id="diocese-and-parish">

          <div className="">
            <h2 className="parish-header-text text-center text-[30px] my-5 w-[fit-content] p-5 mx-auto text-white">DIOCESES AND PARISHES THIS SYSTEM WORKS WITH.</h2>
          </div>
          <div className="dioceses-and-parish-list flex justify-around pb-5">
            <div className="d-box flex flex-col gap-2.5 bg-[#5c131496] px-5">
              <h3 className="d-title text-[30px] my-2.5 border-t w-[fit-content] p-2.5 text-[#fff]">CYANGUGU PARISHES</h3>
              <ul className="cyangugu-parish-list text-white" key="diocese_one">
                {this.Diocese0ne.map((parish) => {
                  return (<li key={parish.id}>{parish.par_name}
                    <ul key={`${parish.id}centrals`}>

                      {
                        this.state.centrals.filter((central) => central.par_id === parish.id).map((cent) => {
                          return <li key={cent.id}>
                          <button onClick={(e)=>this.handleClick(e)}>{cent.cent_name}</button>
                          <div className={`${this.state.isExpanded ? 'isExpanded' : ''}`}>
                          <ul className="list">
                              {
                                this.state.imiryango.filter((umu) => umu.cent_id === cent.id).map((fam, index) => {
                                  return <li key={index}>{fam.umur_name}</li>
                                })
                              }
                            </ul>
                          </div>
                         
                          </li>
                        })
                      }

                    </ul>
                  </li>)
                })}
              </ul>
            </div>
            <div className="d-box flex flex-col gap-2.5 bg-[#5c131496] px-5">
              <h3 className=" d-title text-[30px] my-2.5 border-t w-[fit-content] p-2.5 text-[#fff]">GIKONGORO PARISHES</h3>
              <ul className="gikongoro-parish-list text-white" key={"diocese_two"}>
                {this.DioceseTwo.map((parish) => {
                  return <li key={parish.id}>{parish.par_name}
                    <ul key={`${parish.id}centrals`}>

                      {
                        this.state.centrals.filter((central) => central.par_id === parish.id).map((cent) => {
                          return <li key={cent.id}>{cent.cent_name}
                            <ul>
                              {
                                this.state.imiryango.filter((umu) => umu.cent_id === cent.id).map((fam, index) => {
                                  return <li key={index}>{fam.umur_name}</li>
                                })
                              }
                            </ul>
                          </li>
                        })
                      }

                    </ul>
                  </li>
                })}
              </ul>
            </div>
          </div>

        </div>
        <div className="copyright bg-white flex justify-between px-4 mx-0">
          <span>&copy; {new Date().getFullYear()} Caritas. All Rights reserved</span>
          <span>
            Developed by <a href="https://www.natcomservice.com" target="_blank" className="d-link text-[#047]">N@tcom Services &trade;</a>
          </span>
        </div>
      </div>
    )
  }

}

export default Homepage