import React, { Component, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {confirmDioceseUser} from '../../../../data/authServises.js'
class confirmDUser extends Component{
    componentDidMount(){
        
        const {token}=useParams();
        const confirmUser = async () => {
            const { res: message } = await confirmDioceseUser(token);
            alert(message);
            if (message) window.location = '/login';
        }
        confirmUser();
    }
    render(){
    return (
        <div>confirmParishUser</div>
    )
}
}

export default confirmDUser