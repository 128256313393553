import { apiUrl } from "src/components/context/apiUrl";
import http from "./httpService";

// Note: The pending should be considered as Suspended.

// apiUrl +
const user = JSON.parse(localStorage.getItem("currentUserData"));

export function ActOnNotification(notificationId) {
  return http.patch(`${apiUrl}/operations/Notification/${notificationId}/`, {
    user_id: user.user_id,
  });
}

export function getNotifications() {
  return http.get(apiUrl + "/notifications/" + user.user_id + "/");
}

export function postHistoryVariable(history) {
  return http.post(apiUrl + "/operations/HistoryVariable/", history);
}

export function getVariableHistory(variable_id) {
  return http.get(apiUrl + `/historyVariable/${variable_id}/`);
}

export function getUsers() {
  return http.get(apiUrl + "/users/");
}

export function getDioceseUsers() {
  return http.get(apiUrl + `/dioceseUsers/${user.diocese}/`);
}

export function getDioceseAssignees() {
  return http.get(apiUrl + "/assignees/" + user.diocese + "/");
}

export function getProjectManagers() {
  return http.get(apiUrl + "/usermanagers/");
}

export function getDioceseProjectManagers() {
  return http.get(apiUrl + `/dioceseUsermanagers/${user.diocese}/`);
}

export function getOneUser(userId) {
  return http.get(apiUrl + "/user/" + userId + "/");
}

export function updateProfile(userId, data) {
  return http.patch(apiUrl + `/updateProfile/${userId}/`, { data });
}

export function updateOtherProfile(userId, data) {
  return http.patch(apiUrl + `/updateOtherProfile/${userId}/`, { data });
}
export function getDepartments() {
  return http.get(apiUrl + "/operations/departementReg/");
}

export function getUmuryango() {
  return http.get(apiUrl + "/operations/umuryangoReg/");
}

export function postUmuryango(umuryango) {
  return http.post(apiUrl + "/operations/umuryangoReg/", umuryango);
}

export function postDepartments(department) {
  return http.post(apiUrl + "/operations/departementReg/", department);
}

export function getVariables() {
  return http.get(apiUrl + "/operations/VariableReg/");
}

export function updateVariables(variable_id, progress) {
  return http.patch(apiUrl + "/operations/VariableReg/" + variable_id + "/", {
    progression: parseInt(progress),
  });
}

export function postVariables(variables) {
  return http.post(apiUrl + "/operations/VariableReg/", variables);
}

export function updateVariable(variable) {
  if (variable.subtract || variable.add) {
    return http.put(apiUrl + "/operations/VariableReg/" + variable.id + "/", {
      subtract: variable.subtract,
      add: variable.add,
    });
  }
  if (!variable.subtract && !variable.add) {
    return http.put(apiUrl + "/operations/VariableReg/" + variable.id + "/", {
      name: variable.name,
      total: variable.total,
      progress: variable.progress,
      type: variable.type,
      per_person: variable.per_person,
      subtract: variable.subtract,
    });
  }
}

export function deleteVariable(variable) {
  return http.delete(apiUrl + "/operations/VariableReg/" + variable.id + "/");
}

export function getParish() {
  return http.get(apiUrl + "/operations/parishReg/");
}

export function getHealthCenters() {
  return http.get(apiUrl + "/operations/HealthCenterReg/");
}

export function postHealthCenter(healthCenter) {
  return http.post(apiUrl + "/operations/HealthCenterReg/", healthCenter);
}

export function getHealthCenterData(healthCenterId) {
  return http.get(
    apiUrl + "/operations/HealthCenterDataReg/" + healthCenterId + "/"
  );
}

export function postHealthCenterData(healthCenterData) {
  if (healthCenterData.id) {
    const body = { ...healthCenterData };
    delete body.id;
    delete body.created_timestamp;
    return http.put(
      apiUrl + "/operations/HealthCenterDataReg/" + healthCenterData.id + "/",
      {
        month: healthCenterData.month,
        year: healthCenterData.year,
        health_center: healthCenterData.health_center.id,
        population_cible: healthCenterData.population_cible,
        nombre_des_femmes_attendues_en_cpn:
          healthCenterData.nombre_des_femmes_attendues_en_cpn,
        consultation_externe_masculin:
          healthCenterData.consultation_externe_masculin,
        consultation_externe_feminin:
          healthCenterData.consultation_externe_feminin,
        hospitalisation_lits: healthCenterData.hospitalisation_lits,
        hospitalisation_hospitalisees:
          healthCenterData.hospitalisation_hospitalisees,
        laboratoir_gouttes: healthCenterData.laboratoir_gouttes,
        laboratoir_chantillons: healthCenterData.laboratoir_chantillons,
        vppa_conseille_teste_vih: healthCenterData.vppa_conseille_teste_vih,
        vppa_teste_vih_positif: healthCenterData.vppa_teste_vih_positif,
        vppa_total_patients_moins_15ans_arv:
          healthCenterData.vppa_total_patients_moins_15ans_arv,
        vppa_total_patients_plus_15ans_arv:
          healthCenterData.vppa_total_patients_plus_15ans_arv,
        vppa_enfants_exposes_vih_en_suivi:
          healthCenterData.vppa_enfants_exposes_vih_en_suivi,
        vppa_patients_sous_arv_anti_tuberculeux:
          healthCenterData.vppa_patients_sous_arv_anti_tuberculeux,
        vppa_couples_discordants: healthCenterData.vppa_couples_discordants,
        vppa_ists_traites: healthCenterData.vppa_ists_traites,
        cpn_femmes_enceintes_nouvelles_inscrites_en_cpn:
          healthCenterData.cpn_femmes_enceintes_nouvelles_inscrites_en_cpn,
        cpn_femmes_enceintes_ayant_fait_4_visites_standards_de_cpn:
          healthCenterData.cpn_femmes_enceintes_ayant_fait_4_visites_standards_de_cpn,
        accouchement_assiste_total_accouchements_assistes:
          healthCenterData.accouchement_assiste_total_accouchements_assistes,
        accouchement_assiste_a_domicile:
          healthCenterData.accouchement_assiste_a_domicile,
        accouchement_assiste_femmesde_16_19_ans:
          healthCenterData.accouchement_assiste_femmesde_16_19_ans,
        accouchement_assiste_enfants_morts_nes:
          healthCenterData.accouchement_assiste_enfants_morts_nes,
        accouchement_assiste_mort_maternelle:
          healthCenterData.accouchement_assiste_mort_maternelle,
        nutrition_enfants_moins_5_ans_ayant_depistage_malnutrition:
          healthCenterData.nutrition_enfants_moins_5_ans_ayant_depistage_malnutrition,
        nutrition_enfants_malnourris:
          healthCenterData.nutrition_enfants_malnourris,
        nutrition_enfants_presents_programme_rehabilitation_fin_mois:
          healthCenterData.nutrition_enfants_presents_programme_rehabilitation_fin_mois,
        vaccination_enfants_vaccines_contre_le_bcg:
          healthCenterData.vaccination_enfants_vaccines_contre_le_bcg,
        vaccination_enfants_vaccines_contre_rougeole_rubeole_mr2:
          healthCenterData.vaccination_enfants_vaccines_contre_rougeole_rubeole_mr2,
        iec_sensibilisees_sur_prevention_malaria:
          healthCenterData.iec_sensibilisees_sur_prevention_malaria,
        iec_sensibilisees_sur_prevention_sida:
          healthCenterData.iec_sensibilisees_sur_prevention_sida,
        iec_sensibilisees_sur_prevention_tuberculose:
          healthCenterData.iec_sensibilisees_sur_prevention_tuberculose,
        iec_sensibilisees_sur_prevention_maladies_non_transmissibles:
          healthCenterData.iec_sensibilisees_sur_prevention_maladies_non_transmissibles,
        iec_sensibilisees_sur_prevention_malnutrition:
          healthCenterData.iec_sensibilisees_sur_prevention_malnutrition,
        finance_recettes: healthCenterData.finance_recettes,
        finance_depenses: healthCenterData.finance_depenses,
        finance_creance: healthCenterData.finance_creance,
        finance_dette: healthCenterData.finance_dette,
        finance_total_end_of_month: healthCenterData.finance_total_end_of_month,
        taux_activities: healthCenterData.taux_activities,
        taux_adhesion_ms: healthCenterData.taux_adhesion_ms,
        taux_cpn_4_visites_standards:
          healthCenterData.taux_cpn_4_visites_standards,
        taux_cpc: healthCenterData.taux_cpc,
        taux_accouchement: healthCenterData.taux_accouchement,
        taux_vct_pit: healthCenterData.taux_vct_pit,
        taux_var_rubeole: healthCenterData.taux_var_rubeole,
        nombre_de_cas_paludisme_confirme:
          healthCenterData.nombre_de_cas_paludisme_confirme,
        nombre_de_cas_parasitose_intestinale:
          healthCenterData.nombre_de_cas_parasitose_intestinale,
        nombre_de_cas_planning_familiale:
          healthCenterData.nombre_de_cas_planning_familiale,
        user_id: user.user_id,
      }
    );
  }
  return http.post(apiUrl + "/operations/HealthCenterDataReg/", {
    month: healthCenterData.month,
    year: healthCenterData.year,
    health_center: healthCenterData.health_center,
    population_cible: healthCenterData.population_cible,
    nombre_des_femmes_attendues_en_cpn:
      healthCenterData.nombre_des_femmes_attendues_en_cpn,
    consultation_externe_masculin:
      healthCenterData.consultation_externe_masculin,
    consultation_externe_feminin: healthCenterData.consultation_externe_feminin,
    hospitalisation_lits: healthCenterData.hospitalisation_lits,
    hospitalisation_hospitalisees:
      healthCenterData.hospitalisation_hospitalisees,
    laboratoir_gouttes: healthCenterData.laboratoir_gouttes,
    laboratoir_chantillons: healthCenterData.laboratoir_chantillons,
    vppa_conseille_teste_vih: healthCenterData.vppa_conseille_teste_vih,
    vppa_teste_vih_positif: healthCenterData.vppa_teste_vih_positif,
    vppa_total_patients_moins_15ans_arv:
      healthCenterData.vppa_total_patients_moins_15ans_arv,
    vppa_total_patients_plus_15ans_arv:
      healthCenterData.vppa_total_patients_plus_15ans_arv,
    vppa_enfants_exposes_vih_en_suivi:
      healthCenterData.vppa_enfants_exposes_vih_en_suivi,
    vppa_patients_sous_arv_anti_tuberculeux:
      healthCenterData.vppa_patients_sous_arv_anti_tuberculeux,
    vppa_couples_discordants: healthCenterData.vppa_couples_discordants,
    vppa_ists_traites: healthCenterData.vppa_ists_traites,
    cpn_femmes_enceintes_nouvelles_inscrites_en_cpn:
      healthCenterData.cpn_femmes_enceintes_nouvelles_inscrites_en_cpn,
    cpn_femmes_enceintes_ayant_fait_4_visites_standards_de_cpn:
      healthCenterData.cpn_femmes_enceintes_ayant_fait_4_visites_standards_de_cpn,
    accouchement_assiste_total_accouchements_assistes:
      healthCenterData.accouchement_assiste_total_accouchements_assistes,
    accouchement_assiste_a_domicile:
      healthCenterData.accouchement_assiste_a_domicile,
    accouchement_assiste_femmesde_16_19_ans:
      healthCenterData.accouchement_assiste_femmesde_16_19_ans,
    accouchement_assiste_enfants_morts_nes:
      healthCenterData.accouchement_assiste_enfants_morts_nes,
    accouchement_assiste_mort_maternelle:
      healthCenterData.accouchement_assiste_mort_maternelle,
    nutrition_enfants_moins_5_ans_ayant_depistage_malnutrition:
      healthCenterData.nutrition_enfants_moins_5_ans_ayant_depistage_malnutrition,
    nutrition_enfants_malnourris: healthCenterData.nutrition_enfants_malnourris,
    nutrition_enfants_presents_programme_rehabilitation_fin_mois:
      healthCenterData.nutrition_enfants_presents_programme_rehabilitation_fin_mois,
    vaccination_enfants_vaccines_contre_le_bcg:
      healthCenterData.vaccination_enfants_vaccines_contre_le_bcg,
    vaccination_enfants_vaccines_contre_rougeole_rubeole_mr2:
      healthCenterData.vaccination_enfants_vaccines_contre_rougeole_rubeole_mr2,
    iec_sensibilisees_sur_prevention_malaria:
      healthCenterData.iec_sensibilisees_sur_prevention_malaria,
    iec_sensibilisees_sur_prevention_sida:
      healthCenterData.iec_sensibilisees_sur_prevention_sida,
    iec_sensibilisees_sur_prevention_tuberculose:
      healthCenterData.iec_sensibilisees_sur_prevention_tuberculose,
    iec_sensibilisees_sur_prevention_maladies_non_transmissibles:
      healthCenterData.iec_sensibilisees_sur_prevention_maladies_non_transmissibles,
    iec_sensibilisees_sur_prevention_malnutrition:
      healthCenterData.iec_sensibilisees_sur_prevention_malnutrition,
    finance_recettes: healthCenterData.finance_recettes,
    finance_depenses: healthCenterData.finance_depenses,
    finance_creance: healthCenterData.finance_creance,
    finance_dette: healthCenterData.finance_dette,
    finance_total_end_of_month: healthCenterData.finance_total_end_of_month,
    taux_activities: healthCenterData.taux_activities,
    taux_adhesion_ms: healthCenterData.taux_adhesion_ms,
    taux_cpn_4_visites_standards: healthCenterData.taux_cpn_4_visites_standards,
    taux_cpc: healthCenterData.taux_cpc,
    taux_accouchement: healthCenterData.taux_accouchement,
    taux_vct_pit: healthCenterData.taux_vct_pit,
    taux_var_rubeole: healthCenterData.taux_var_rubeole,
    nombre_de_cas_paludisme_confirme:
      healthCenterData.nombre_de_cas_paludisme_confirme,
    nombre_de_cas_parasitose_intestinale:
      healthCenterData.nombre_de_cas_parasitose_intestinale,
    nombre_de_cas_planning_familiale:
      healthCenterData.nombre_de_cas_planning_familiale,
    user_id: user.user_id,
  });
}

export function updateHealthCenterData(healthCenterData) {}

export function getDetailedHealthCenterData() {
  return http.get(apiUrl + "/operations/DetailedHealthCenterDataReg/");
}

export function getDioceseDetailedHealthCenterData() {
  return http.get(apiUrl + `/dioceseDetailedHCData/${user.diocese}/`);
}

export function getDioceseDetailedHospitalData() {
  return http.get(apiUrl + `/dioceseDetailedHospData/${user.diocese}/`);
}

export function deleteHealthCenterData(HealthCenterDataId) {
  return http.delete(
    apiUrl + "/operations/HealthCenterDataReg/" + HealthCenterDataId + "/"
  );
}

export function getDetailedOneHealthCenterData(heathCenterId) {
  return http.get(
    apiUrl + "/operations/DetailedHealthCenterDataReg/" + heathCenterId + "/"
  );
}

export function getDeletedHCData() {
  return http.get(apiUrl + "/hcData/deleted/");
}

export function recycleHCData(pk) {
  return http.patch(apiUrl + `/hcReports/${pk}/recycle/`);
}

export function getHospitals() {
  return http.get(apiUrl + "/operations/HospitalReg/");
}

export function postHospital(hospital) {
  return http.post(apiUrl + "/operations/HospitalReg/", hospital);
}

export function getHospitalData() {
  return http.get(apiUrl + "/operations/HospitalDataReg/");
}

export function deleteHospitalData(hospitalDataId) {
  return http.delete(
    apiUrl + "/operations/HospitalDataReg/" + hospitalDataId + "/"
  );
}
export function getOneDetailedHospitalData(hospitalDataId) {
  return http.get(
    apiUrl + "/operations/DetailedHospitalDataReg/" + hospitalDataId + "/"
  );
}

export function getDetailedHospitalData() {
  return http.get(apiUrl + "/operations/DetailedHospitalDataReg/");
}

export function getSingleHospitalData(reportId) {
  return http.get(apiUrl + "/operations/HospitalDataReg/" + reportId + "/");
}

export function postHospitalData(hospitalData) {
  if (hospitalData.id) {
    const body = { ...hospitalData };
    delete body.id;
    delete body.created_timestamp;
    delete body.total_revenues;
    delete body.exp_total_expenditures;
    return http.put(
      apiUrl + "/operations/HospitalDataReg/" + hospitalData.id + "/",
      {
        month: hospitalData.month,
        year: hospitalData.year,
        hospital: hospitalData.hospital.id,
        target_population: hospitalData.target_population,
        number_of_hospital_beds: hospitalData.number_of_hospital_beds,
        opd_consultation: hospitalData.opd_consultation,
        deliveries_eutocic: hospitalData.deliveries_eutocic,
        deliveries_c_section: hospitalData.deliveries_c_section,
        live_births: hospitalData.live_births,
        number_of_inpatients_in_internal_medecine:
          hospitalData.number_of_inpatients_in_internal_medecine,
        number_of_inpatients_in_surgery:
          hospitalData.number_of_inpatients_in_surgery,
        number_of_inpatients_in_paediatris:
          hospitalData.number_of_inpatients_in_paediatris,
        number_of_inpatients_in_neonatology:
          hospitalData.number_of_inpatients_in_neonatology,
        number_of_inpatients_in_maternity:
          hospitalData.number_of_inpatients_in_maternity,
        number_of_inpatients_in_others:
          hospitalData.number_of_inpatients_in_others,
        children_malnourished_in_rehabilitation:
          hospitalData.children_malnourished_in_rehabilitation,
        clients_tested_for_hiv: hospitalData.clients_tested_for_hiv,
        clients_tested_hiv_positive: hospitalData.clients_tested_hiv_positive,
        clients_under_art_new_cases: hospitalData.clients_under_art_new_cases,
        clients_under_art_total: hospitalData.clients_under_art_total,
        number_of_blood_smear_for_malaria:
          hospitalData.number_of_blood_smear_for_malaria,
        number_of_blood_smear_positive:
          hospitalData.number_of_blood_smear_positive,
        number_of_sputum_examination_for_tb_diagnosis:
          hospitalData.number_of_sputum_examination_for_tb_diagnosis,
        number_of_tb_positive: hospitalData.number_of_tb_positive,
        patients_under_anti_tb_drugs_new_cases:
          hospitalData.patients_under_anti_tb_drugs_new_cases,
        patients_under_anti_tb_drugs_total:
          hospitalData.patients_under_anti_tb_drugs_total,
        number_of_ncds_new_cases: hospitalData.number_of_ncds_new_cases,
        number_of_ncds_total: hospitalData.number_of_ncds_total,
        number_of_palliative_care_new_cases:
          hospitalData.number_of_palliative_care_new_cases,
        number_of_palliative_care_total:
          hospitalData.number_of_palliative_care_total,
        nombre_de_deces: hospitalData.nombre_de_deces,
        ten_first_causes_of_opd_consultation:
          hospitalData.ten_first_causes_of_opd_consultation,
        ten_first_causes_of_hospitalization:
          hospitalData.ten_first_causes_of_hospitalization,
        ten_first_causes_of_deaths: hospitalData.ten_first_causes_of_deaths,
        revenues_consultation: hospitalData.revenues_consultation,
        revenues_laboratory: hospitalData.revenues_laboratory,
        revenues_hospitalisation: hospitalData.revenues_hospitalisation,
        revenues_ambulances: hospitalData.revenues_ambulances,
        revenues_sales_of_drugs: hospitalData.revenues_sales_of_drugs,
        revenues_imaging_scanning: hospitalData.revenues_imaging_scanning,
        revenues_ophtalmology: hospitalData.revenues_ophtalmology,
        other_health_related_revenues:
          hospitalData.other_health_related_revenues,
        transfers_from_Rusizi_District:
          hospitalData.transfers_from_Rusizi_District,
        transfers_from_other_government_reporting_entities:
          hospitalData.transfers_from_other_government_reporting_entities,
        total_revenues:
          parseFloat(hospitalData.revenues_consultation) +
          parseFloat(hospitalData.revenues_laboratory) +
          parseFloat(hospitalData.revenues_hospitalisation) +
          parseFloat(hospitalData.revenues_ambulances) +
          parseFloat(hospitalData.revenues_sales_of_drugs) +
          parseFloat(hospitalData.revenues_imaging_scanning) +
          parseFloat(hospitalData.revenues_ophtalmology) +
          parseFloat(hospitalData.other_health_related_revenues) +
          parseFloat(hospitalData.transfers_from_Rusizi_District) +
          parseFloat(
            hospitalData.transfers_from_other_government_reporting_entities
          ),
        exp_stationery_printing_cons: hospitalData.exp_stationery_printing_cons,
        exp_beverages_tea_coffee: hospitalData.exp_beverages_tea_coffee,
        exp_cleaning_n_maintenance: hospitalData.exp_cleaning_n_maintenance,
        exp_photos_camera_microfilm: hospitalData.exp_photos_camera_microfilm,
        exp_food_supplies: hospitalData.exp_food_supplies,
        exp_equipment_accs: hospitalData.exp_equipment_accs,
        exp_electricity_bills: hospitalData.exp_electricity_bills,
        exp_rental_costs_vehicle: hospitalData.exp_rental_costs_vehicle,
        exp_postage_n_courier: hospitalData.exp_postage_n_courier,
        exp_tel_n_fax: hospitalData.exp_tel_n_fax,
        exp_internet: hospitalData.exp_internet,
        exp_vehicles_moto_insurance: hospitalData.exp_vehicles_moto_insurance,
        exp_public_relations: hospitalData.exp_public_relations,
        exp_coordination_med: hospitalData.exp_coordination_med,
        exp_pro_n_contr_services: hospitalData.exp_pro_n_contr_services,
        exp_legal_fees: hospitalData.exp_legal_fees,
        exp_pro_n_contr_services_salary:
          hospitalData.exp_pro_n_contr_services_salary,
        exp_pro_n_contr_services_pbf: hospitalData.exp_pro_n_contr_services_pbf,
        exp_cleaning_services: hospitalData.exp_cleaning_services,
        exp_transportation_cost_dom_business_travel:
          hospitalData.exp_transportation_cost_dom_business_travel,
        exp_dom_perdiem: hospitalData.exp_dom_perdiem,
        exp_fuel_n_rubricants: hospitalData.exp_fuel_n_rubricants,
        exp_mission_allowances: hospitalData.exp_mission_allowances,
        exp_maintenance_n_repair_hospital_buildings:
          hospitalData.exp_maintenance_n_repair_hospital_buildings,
        exp_maintenance_n_repair_veh_moto:
          hospitalData.exp_maintenance_n_repair_veh_moto,
        exp_maintenance_n_repair_off_equip:
          hospitalData.exp_maintenance_n_repair_off_equip,
        exp_maintenance_n_repair_other_equip:
          hospitalData.exp_maintenance_n_repair_other_equip,
        exp_vehicle_spare_parts: hospitalData.exp_vehicle_spare_parts,
        exp_equip_spare_parts: hospitalData.exp_equip_spare_parts,
        exp_training_costs: hospitalData.exp_training_costs,
        exp_medical_supplies: hospitalData.exp_medical_supplies,
        exp_clothing_uniform_curtains:
          hospitalData.exp_clothing_uniform_curtains,
        exp_security_company: hospitalData.exp_security_company,
        exp_transfer_central_gov_inst:
          hospitalData.exp_transfer_central_gov_inst,
        exp_transfer_hcs: hospitalData.exp_transfer_hcs,
        exp_transfer_non_reporting_entities:
          hospitalData.exp_transfer_non_reporting_entities,
        exp_social_assistance: hospitalData.exp_social_assistance,
        exp_sitting_allowances: hospitalData.exp_sitting_allowances,
        exp_hospital_buildings: hospitalData.exp_hospital_buildings,
        exp_office_eqipment_n_furniture:
          hospitalData.exp_office_eqipment_n_furniture,
        exp_ict_equipments: hospitalData.exp_ict_equipments,
        exp_lab_n_med_equipments: hospitalData.exp_lab_n_med_equipments,
        exp_total_expenditures:
          parseFloat(hospitalData.exp_stationery_printing_cons) +
          parseFloat(hospitalData.exp_beverages_tea_coffee) +
          parseFloat(hospitalData.exp_cleaning_n_maintenance) +
          parseFloat(hospitalData.exp_photos_camera_microfilm) +
          parseFloat(hospitalData.exp_food_supplies) +
          parseFloat(hospitalData.exp_equipment_accs) +
          parseFloat(hospitalData.exp_electricity_bills) +
          parseFloat(hospitalData.exp_rental_costs_vehicle) +
          parseFloat(hospitalData.exp_postage_n_courier) +
          parseFloat(hospitalData.exp_tel_n_fax) +
          parseFloat(hospitalData.exp_internet) +
          parseFloat(hospitalData.exp_vehicles_moto_insurance) +
          parseFloat(hospitalData.exp_public_relations) +
          parseFloat(hospitalData.exp_coordination_med) +
          parseFloat(hospitalData.exp_legal_fees) +
          parseFloat(hospitalData.exp_pro_n_contr_services_salary) +
          parseFloat(hospitalData.exp_pro_n_contr_services_pbf) +
          parseFloat(hospitalData.exp_cleaning_services) +
          parseFloat(hospitalData.exp_transportation_cost_dom_business_travel) +
          parseFloat(hospitalData.exp_dom_perdiem) +
          parseFloat(hospitalData.exp_fuel_n_rubricants) +
          parseFloat(hospitalData.exp_mission_allowances) +
          parseFloat(hospitalData.exp_maintenance_n_repair_hospital_buildings) +
          parseFloat(hospitalData.exp_maintenance_n_repair_veh_moto) +
          parseFloat(hospitalData.exp_maintenance_n_repair_off_equip) +
          parseFloat(hospitalData.exp_maintenance_n_repair_other_equip) +
          parseFloat(hospitalData.exp_vehicle_spare_parts) +
          parseFloat(hospitalData.exp_equip_spare_parts) +
          parseFloat(hospitalData.exp_training_costs) +
          parseFloat(hospitalData.exp_medical_supplies) +
          parseFloat(hospitalData.exp_clothing_uniform_curtains) +
          parseFloat(hospitalData.exp_security_company) +
          parseFloat(hospitalData.exp_transfer_central_gov_inst) +
          parseFloat(hospitalData.exp_transfer_hcs) +
          parseFloat(hospitalData.exp_transfer_non_reporting_entities) +
          parseFloat(hospitalData.exp_social_assistance) +
          parseFloat(hospitalData.exp_sitting_allowances) +
          parseFloat(hospitalData.exp_hospital_buildings) +
          parseFloat(hospitalData.exp_office_eqipment_n_furniture) +
          parseFloat(hospitalData.exp_ict_equipments) +
          parseFloat(hospitalData.exp_lab_n_med_equipments),
        list_receivables: hospitalData.list_receivables,
        list_payables: hospitalData.list_payables,
        bank_balances: hospitalData.bank_balances,
        user_id: user.user_id,
      }
    );
  }
  return http.post(apiUrl + "/operations/HospitalDataReg/", {
    month: hospitalData.month,
    year: hospitalData.year,
    hospital: hospitalData.hospital,
    target_population: hospitalData.target_population,
    number_of_hospital_beds: hospitalData.number_of_hospital_beds,
    opd_consultation: hospitalData.opd_consultation,
    deliveries_eutocic: hospitalData.deliveries_eutocic,
    deliveries_c_section: hospitalData.deliveries_c_section,
    live_births: hospitalData.live_births,
    number_of_inpatients_in_internal_medecine:
      hospitalData.number_of_inpatients_in_internal_medecine,
    number_of_inpatients_in_surgery:
      hospitalData.number_of_inpatients_in_surgery,
    number_of_inpatients_in_paediatris:
      hospitalData.number_of_inpatients_in_paediatris,
    number_of_inpatients_in_neonatology:
      hospitalData.number_of_inpatients_in_neonatology,
    number_of_inpatients_in_maternity:
      hospitalData.number_of_inpatients_in_maternity,
    number_of_inpatients_in_others: hospitalData.number_of_inpatients_in_others,
    children_malnourished_in_rehabilitation:
      hospitalData.children_malnourished_in_rehabilitation,
    clients_tested_for_hiv: hospitalData.clients_tested_for_hiv,
    clients_tested_hiv_positive: hospitalData.clients_tested_hiv_positive,
    clients_under_art_new_cases: hospitalData.clients_under_art_new_cases,
    clients_under_art_total: hospitalData.clients_under_art_total,
    number_of_blood_smear_for_malaria:
      hospitalData.number_of_blood_smear_for_malaria,
    number_of_blood_smear_positive: hospitalData.number_of_blood_smear_positive,
    number_of_sputum_examination_for_tb_diagnosis:
      hospitalData.number_of_sputum_examination_for_tb_diagnosis,
    number_of_tb_positive: hospitalData.number_of_tb_positive,
    patients_under_anti_tb_drugs_new_cases:
      hospitalData.patients_under_anti_tb_drugs_new_cases,
    patients_under_anti_tb_drugs_total:
      hospitalData.patients_under_anti_tb_drugs_total,
    number_of_ncds_new_cases: hospitalData.number_of_ncds_new_cases,
    number_of_ncds_total: hospitalData.number_of_ncds_total,
    number_of_palliative_care_new_cases:
      hospitalData.number_of_palliative_care_new_cases,
    number_of_palliative_care_total:
      hospitalData.number_of_palliative_care_total,
    nombre_de_deces: hospitalData.nombre_de_deces,
    ten_first_causes_of_opd_consultation:
      hospitalData.ten_first_causes_of_opd_consultation,
    ten_first_causes_of_hospitalization:
      hospitalData.ten_first_causes_of_hospitalization,
    ten_first_causes_of_deaths: hospitalData.ten_first_causes_of_deaths,
    revenues_consultation: hospitalData.revenues_consultation,
    revenues_laboratory: hospitalData.revenues_laboratory,
    revenues_hospitalisation: hospitalData.revenues_hospitalisation,
    revenues_ambulances: hospitalData.revenues_ambulances,
    revenues_sales_of_drugs: hospitalData.revenues_sales_of_drugs,
    revenues_imaging_scanning: hospitalData.revenues_imaging_scanning,
    revenues_ophtalmology: hospitalData.revenues_ophtalmology,
    other_health_related_revenues: hospitalData.other_health_related_revenues,
    transfers_from_Rusizi_District: hospitalData.transfers_from_Rusizi_District,
    transfers_from_other_government_reporting_entities:
      hospitalData.transfers_from_other_government_reporting_entities,
    total_revenues:
      parseFloat(hospitalData.revenues_consultation) +
      parseFloat(hospitalData.revenues_laboratory) +
      parseFloat(hospitalData.revenues_hospitalisation) +
      parseFloat(hospitalData.revenues_ambulances) +
      parseFloat(hospitalData.revenues_sales_of_drugs) +
      parseFloat(hospitalData.revenues_imaging_scanning) +
      parseFloat(hospitalData.revenues_ophtalmology) +
      parseFloat(hospitalData.other_health_related_revenues) +
      parseFloat(hospitalData.transfers_from_Rusizi_District) +
      parseFloat(
        hospitalData.transfers_from_other_government_reporting_entities
      ),
    exp_stationery_printing_cons: hospitalData.exp_stationery_printing_cons,
    exp_beverages_tea_coffee: hospitalData.exp_beverages_tea_coffee,
    exp_cleaning_n_maintenance: hospitalData.exp_cleaning_n_maintenance,
    exp_photos_camera_microfilm: hospitalData.exp_photos_camera_microfilm,
    exp_food_supplies: hospitalData.exp_food_supplies,
    exp_equipment_accs: hospitalData.exp_equipment_accs,
    exp_electricity_bills: hospitalData.exp_electricity_bills,
    exp_rental_costs_vehicle: hospitalData.exp_rental_costs_vehicle,
    exp_postage_n_courier: hospitalData.exp_postage_n_courier,
    exp_tel_n_fax: hospitalData.exp_tel_n_fax,
    exp_internet: hospitalData.exp_internet,
    exp_vehicles_moto_insurance: hospitalData.exp_vehicles_moto_insurance,
    exp_public_relations: hospitalData.exp_public_relations,
    exp_coordination_med: hospitalData.exp_coordination_med,
    exp_pro_n_contr_services: hospitalData.exp_pro_n_contr_services,
    exp_legal_fees: hospitalData.exp_legal_fees,
    exp_pro_n_contr_services_salary:
      hospitalData.exp_pro_n_contr_services_salary,
    exp_pro_n_contr_services_pbf: hospitalData.exp_pro_n_contr_services_pbf,
    exp_cleaning_services: hospitalData.exp_cleaning_services,
    exp_transportation_cost_dom_business_travel:
      hospitalData.exp_transportation_cost_dom_business_travel,
    exp_dom_perdiem: hospitalData.exp_dom_perdiem,
    exp_fuel_n_rubricants: hospitalData.exp_fuel_n_rubricants,
    exp_mission_allowances: hospitalData.exp_mission_allowances,
    exp_maintenance_n_repair_hospital_buildings:
      hospitalData.exp_maintenance_n_repair_hospital_buildings,
    exp_maintenance_n_repair_veh_moto:
      hospitalData.exp_maintenance_n_repair_veh_moto,
    exp_maintenance_n_repair_off_equip:
      hospitalData.exp_maintenance_n_repair_off_equip,
    exp_maintenance_n_repair_other_equip:
      hospitalData.exp_maintenance_n_repair_other_equip,
    exp_vehicle_spare_parts: hospitalData.exp_vehicle_spare_parts,
    exp_equip_spare_parts: hospitalData.exp_equip_spare_parts,
    exp_training_costs: hospitalData.exp_training_costs,
    exp_medical_supplies: hospitalData.exp_medical_supplies,
    exp_clothing_uniform_curtains: hospitalData.exp_clothing_uniform_curtains,
    exp_security_company: hospitalData.exp_security_company,
    exp_transfer_central_gov_inst: hospitalData.exp_transfer_central_gov_inst,
    exp_transfer_hcs: hospitalData.exp_transfer_hcs,
    exp_transfer_non_reporting_entities:
      hospitalData.exp_transfer_non_reporting_entities,
    exp_social_assistance: hospitalData.exp_social_assistance,
    exp_sitting_allowances: hospitalData.exp_sitting_allowances,
    exp_hospital_buildings: hospitalData.exp_hospital_buildings,
    exp_office_eqipment_n_furniture:
      hospitalData.exp_office_eqipment_n_furniture,
    exp_ict_equipments: hospitalData.exp_ict_equipments,
    exp_lab_n_med_equipments: hospitalData.exp_lab_n_med_equipments,
    exp_total_expenditures:
      parseFloat(hospitalData.exp_stationery_printing_cons) +
      parseFloat(hospitalData.exp_beverages_tea_coffee) +
      parseFloat(hospitalData.exp_cleaning_n_maintenance) +
      parseFloat(hospitalData.exp_photos_camera_microfilm) +
      parseFloat(hospitalData.exp_food_supplies) +
      parseFloat(hospitalData.exp_equipment_accs) +
      parseFloat(hospitalData.exp_electricity_bills) +
      parseFloat(hospitalData.exp_rental_costs_vehicle) +
      parseFloat(hospitalData.exp_postage_n_courier) +
      parseFloat(hospitalData.exp_tel_n_fax) +
      parseFloat(hospitalData.exp_internet) +
      parseFloat(hospitalData.exp_vehicles_moto_insurance) +
      parseFloat(hospitalData.exp_public_relations) +
      parseFloat(hospitalData.exp_coordination_med) +
      parseFloat(hospitalData.exp_legal_fees) +
      parseFloat(hospitalData.exp_pro_n_contr_services_salary) +
      parseFloat(hospitalData.exp_pro_n_contr_services_pbf) +
      parseFloat(hospitalData.exp_cleaning_services) +
      parseFloat(hospitalData.exp_transportation_cost_dom_business_travel) +
      parseFloat(hospitalData.exp_dom_perdiem) +
      parseFloat(hospitalData.exp_fuel_n_rubricants) +
      parseFloat(hospitalData.exp_mission_allowances) +
      parseFloat(hospitalData.exp_maintenance_n_repair_hospital_buildings) +
      parseFloat(hospitalData.exp_maintenance_n_repair_veh_moto) +
      parseFloat(hospitalData.exp_maintenance_n_repair_off_equip) +
      parseFloat(hospitalData.exp_maintenance_n_repair_other_equip) +
      parseFloat(hospitalData.exp_vehicle_spare_parts) +
      parseFloat(hospitalData.exp_equip_spare_parts) +
      parseFloat(hospitalData.exp_training_costs) +
      parseFloat(hospitalData.exp_medical_supplies) +
      parseFloat(hospitalData.exp_clothing_uniform_curtains) +
      parseFloat(hospitalData.exp_security_company) +
      parseFloat(hospitalData.exp_transfer_central_gov_inst) +
      parseFloat(hospitalData.exp_transfer_hcs) +
      parseFloat(hospitalData.exp_transfer_non_reporting_entities) +
      parseFloat(hospitalData.exp_social_assistance) +
      parseFloat(hospitalData.exp_sitting_allowances) +
      parseFloat(hospitalData.exp_hospital_buildings) +
      parseFloat(hospitalData.exp_office_eqipment_n_furniture) +
      parseFloat(hospitalData.exp_ict_equipments) +
      parseFloat(hospitalData.exp_lab_n_med_equipments),
    list_receivables: hospitalData.list_receivables,
    list_payables: hospitalData.list_payables,
    bank_balances: hospitalData.bank_balances,
    user_id: user.user_id,
  });
}

export function getDeletedHospitalData() {
  return http.get(apiUrl + "/hospitalData/deleted/");
}

export function recycleHospitalData(pk) {
  return http.patch(apiUrl + `/hospitalReports/${pk}/recycle/`);
}

export function getDioceseParish(diocese_id) {
  return http.get(apiUrl + "/parish-in-diocese/" + diocese_id + "/");
}

export function getParishCentral(parish_id) {
  return http.get(apiUrl + "/central-in-parish/" + parish_id + "/");
}

export function getCentralImiryango(central_id) {
  return http.get(
    apiUrl + "/imiryango-remezo-in-central-in-parish/" + central_id + "/"
  );
}

export function getCentImiryango(central_id) {
  return http.get(apiUrl + "/imiryangoYose/");
}

export function getProjectList() {
  return http.get(apiUrl + "/operations/projectsList/");
}

export function getDioceseProjectList() {
  return http.get(apiUrl + `/dioceseProjects/${user.diocese}/`);
}

export function getIndividualDioceseProjectList() {
  return http.get(apiUrl + `/individualproject/${user.user_id}/`);
}

export function getDioceseActivitiesList() {
  return http.get(apiUrl + `/dioceseActivities/${user.diocese}/`);
}

export function getSpecificProjectList() {
  return http.get(apiUrl + "/project-list/" + user.user_id + "/");
}

export function getAssignedProjectList() {
  return http.get(apiUrl + "/parish-project-list/" + user.user_id + "/");
}

export function getProjects() {
  return http.get(apiUrl + "/operations/project/");
}

export function getProject(projectId) {
  return http.get(apiUrl + "/operations/project/" + projectId + "/");
}

export function postProject(project) {
  return http.post(apiUrl + "/operations/project/", project);
}

export function changeProjectStatus(pk, data) {
  return http.patch(apiUrl + `/projectStatus/${pk}/change/`, data);
}

export function updateProject(project) {
  if (project.id) {
    const body = { ...project };
    delete body.id;
    return http.put(apiUrl + "/operations/project/" + project.id + "/", body);
  }
  return http.post(apiUrl + "/operations/project/", body);
}

export function deleteProject(project) {
  return http.delete(apiUrl + "/operations/project/" + project.id + "/");
}

export function eraseProject(projectId) {
  return http.delete(apiUrl + "/operations/projectErase/" + projectId + "/");
}

export function getDeletedProjects() {
  return http.get(apiUrl + "/projects/deleted/");
}

export function getCompletedProjects() {
  return http.get(apiUrl + "/projects/completed/");
}

export function getDioceseCompletedProjects() {
  return http.get(apiUrl + `/dioceseProjects/completed/${user.diocese}/`);
}

export function getPendingProjects() {
  return http.get(apiUrl + "/projects/pending/");
}

export function getDiocesePendingProjects() {
  return http.get(apiUrl + `/dioceseProjects/pending/${user.diocese}/`);
}

export function recycleProject(pk) {
  return http.patch(apiUrl + `/projects/${pk}/recycle/`);
}

export function getDeletedVariables() {
  return http.get(apiUrl + "/variables/deleted/");
}

export function recycleVariable(pk) {
  return http.patch(apiUrl + `/variables/${pk}/recycle/`);
}

export function getProjectDetails(projectId) {
  return http.get(apiUrl + "/project-details/" + projectId + "/");
}

export function getActivityDetails(activityId) {
  return http.get(apiUrl + "/activity-report/" + activityId + "/");
}

export function getDeletedActivities() {
  return http.get(apiUrl + "/activities/deleted/");
}

export function getSpecificActivitiesList() {
  return http.get(apiUrl + "/activity-list/" + user.user_id + "/");
}

export function getParishActivitiesList() {
  return http.get(apiUrl + "/parish-activity-list/" + user.user_id + "/");
}

export function postParish(aPerish) {
  return http.post(apiUrl + "/operations/parishReg/", aPerish);
}

export function getDiocese() {
  return http.get(apiUrl + "/operations/DioceseReg/");
}

export function postDiocese(aDiocese) {
  return http.post(apiUrl + "/operations/DioceseReg/", aDiocese);
}

export function getProjectSection() {
  return http.get(apiUrl + "/operations/section/");
}

export function postProjectSection(projectSection) {
  return http.post(apiUrl + "/operations/section/", projectSection);
}

export function getCentral() {
  return http.get(apiUrl + "/operations/centralReg/");
}

export function postCentral(aCentral) {
  return http.post(apiUrl + "/operations/centralReg/", aCentral);
}

export function getExpectedResults() {
  return http.get(apiUrl + "/operations/expectedResult/");
}

export function getSingleExpectedResults(Result_id) {
  return http.get(apiUrl + "/operations/expectedResult/" + Result_id + "/");
}

export function postExpectedResults(results) {
  if (results.id) {
    const body = { ...results };
    delete body.id;
    return http.put(
      apiUrl + "/operations/expectedResult/" + results.id + "/",
      body
    );
  }
  return http.post(apiUrl + "/operations/expectedResult/", {
    title: results.title,
    project_id: results.project_id,
    intervention_area: results.intervention_area,
    overall_target: results.overall_target,
    indicators: results.indicators,
    beginning_situation: results.beginning_situation,
    user_id: user.user_id,
  });
}

export function getActivity() {
  return http.get(apiUrl + "/operations/activitiesList/");
}

export function getDetailedActivity(activityId) {
  return http.get(apiUrl + "/operations/activitiesList/" + activityId + "/");
}

export function recycleActivity(pk) {
  return http.patch(apiUrl + `/activities/${pk}/recycle/`);
}

export function getYourActivities() {
  return http.get(apiUrl + "/individualActivities/" + user.user_id + "/");
}

export function getLastActivity() {
  return http.get(apiUrl + "/lastActivity/" + user.user_id + "/");
}

export function getOneActivity(activityId) {
  return http.get(apiUrl + "/operations/activities/" + activityId + "/");
}

export function deleteOneActivity(activityId) {
  return http.delete(apiUrl + "/operations/activities/" + activityId + "/");
}

export function eraseOneActivity(activityId) {
  return http.delete(apiUrl + "/operations/activitiesErase/" + activityId + "/");
}

export function postActivity(activity) {
  return http.post(apiUrl + "/operations/activities/", {
    start_date: activity.start_date,
    end_date: activity.end_date,
    location: activity.location,
    target_group: activity.target_group,
    title: activity.title,
    observation: activity.observation,
    project_id: activity.project_id,
    diocese_id: activity.diocese_id,
    expected_result: activity.expected_result,
    dep_id: activity.dep_id,
    progress: activity.progress,
    indicator: activity.indicator,
    activity_status: activity.activity_status,
    user_id: user.user_id,
  });
}

export function updateActivity(activity) {
  if (activity.id) {
    const body = { ...activity };
    delete body.id;
    return http.put(
      apiUrl + "/operations/activities/" + activity.id + "/",
      body
    );
  }
  return http.post(apiUrl + "/operations/activities/", body);
}

export function getActivityReport() {
  return http.get(apiUrl + "/operations/activityReport/");
}

export function getReportDetails(reportId) {
  return http.get(apiUrl + "/detailed-report/" + reportId + "/");
}

export function postActivityReport(activityReport) {
  if (activityReport.id) {
    const body = { ...activityReport };
    delete body.id;
    return http.put(
      apiUrl + "/operations/activityReport/" + activityReport.id + "/",
      {
        achieved_result: activityReport.achieved_result,
        act_id: activityReport.act_id,
        date: activityReport.date,
        comment: activityReport.comment,
        strong_point: activityReport.strong_point,
        justification: activityReport.justification,
        target: activityReport.target,
        difficulties: activityReport.difficulties,
        solutions: activityReport.solutions,
        user_id: user.user_id,
      }
    );
  }
  return http.post(apiUrl + "/operations/activityReport/", {
    achieved_result: activityReport.achieved_result,
    act_id: activityReport.act_id,
    date: activityReport.date,
    comment: activityReport.comment,
    strong_point: activityReport.strong_point,
    justification: activityReport.justification,
    target: activityReport.target,
    difficulties: activityReport.difficulties,
    solutions: activityReport.solutions,
    user_id: user.user_id,
  });
}

export function getStrength() {
  return http.get(apiUrl + "/operations/strength/");
}

export function postStrength(strength) {
  return http.post(apiUrl + "/operations/strength/", strength);
}

export function getWeakness() {
  return http.get(apiUrl + "/operations/weakness/");
}

export function postWeakness(weakness) {
  return http.post(apiUrl + "/operations/weakness/", weakness);
}

export function getDocument() {
  return http.get(apiUrl + "/operations/document/");
}

export function postDocument(formData) {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  return http.post(apiUrl + "/operations/document/", formData, config);
}

export function deleteDocument(doc_id) {
  return http.delete(apiUrl + "/operations/document/" + doc_id + "/");
}

export function getInformation() {
  return http.get(apiUrl + "/operations/information/");
}

export function postInformation(information) {
  return http.post(apiUrl + "/operations/information/", information);
}

export function getSubOne() {
  return http.get(apiUrl + "/operations/subone/");
}

export function postSubOne(subOne) {
  return http.post(apiUrl + "/operations/subone/", subOne);
}

export function getSubTwo() {
  return http.get(apiUrl + "/operations/subtwo/");
}

export function postSubTwo(subTwo) {
  return http.post(apiUrl + "/operations/subtwo/", subTwo);
}

export function getReport() {
  return http.get(apiUrl + "/operations/report/");
}

export function getYourReports() {
  return http.get(apiUrl + "/individualReports/" + user.user_id + "/");
}

export function postReport(report) {
  return http.post(apiUrl + "/operations/report/", report);
}

export function getShareReport() {
  return http.get(apiUrl + "/operations/shareReport/");
}

export function postShareReport(shareReport) {
  return http.post(apiUrl + "/operations/shareReport/", shareReport);
}

export function getSectionCategory() {
  return http.get(apiUrl + "/operations/sectioncategory/");
}

export function postSectionCategory(aSectionCategoty) {
  return http.post(apiUrl + "/operations/sectioncategory/", aSectionCategoty);
}

export function getCategoryFieldReg() {
  return http.get(apiUrl + "/operations/category/");
}

export function postCategoryFieldReg(categoryFieldData) {
  return http.post(apiUrl + "/operations/category/", categoryFieldData);
}

export function postDioceseUser(user) {
  return http.post(apiUrl + "/dioceseUser/", user);
}

export function postParishUser(user) {
  return http.post(apiUrl + "/parishUser/", user);
}
export function postAnimateurUser(user) {
  return http.post(apiUrl + "/animateurUser/", user);
}

export function postOmonieUser(user) {
  return http.post(apiUrl + "/omonieUser/", user);
}
