import React, { Component, Fragment, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "./components/context/userContext";
import Logout from "./components/Logout";
import HomePage from "./views/caritasHomePage/index"
import ProtectedRoute from "./components/ProtectedRoute";
import "./scss/style.scss";
import "./index.css"
import "@fontsource/poppins";
import ResetAnyPassword from "./views/pages/resetPassword";
import ConfirmDioceseUser from "./views/pages/confirmUser/confirmDioceseUser/"
import ConfirmParishUser from "./views/pages/confirmUser/confirmParishUser/"
import ForgotPassword from "./views/pages/resetPassword/forgotPassword";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  state = {};

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("currentUserData"));
    this.setState({ user });
  }

  setIndexPath() {
    const user = JSON.parse(localStorage.getItem("currentUserData"));
    if (user) {
      if (user.is_superuser) {
        return "/dashboard";
      }
      if (user.dioceseUser) {
        if (user.title === "director_of_caritas") {
          return "/dashboard";
        }
        if (user.title === "it_officer") {
          return "/dashboard";
        }
        if (
          user.title === "health_coordinator" ||
          user.title === "development_coordinator" ||
          user.title === "social_coordinator"
        ) {
          return "/Ddashboard";
        }
      }
      if (user.parishUser) {
        return "/Pdashboard";
      }
    }
    return "/";
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
         <ToastContainer />
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
            <Route
                exact
                path="/"
                name="HomePage"
                element={<HomePage/>}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route exact path="/logout" name="Logout" element={<Logout />} />
              <Route exact path="/dioseceUserConfirm/:token" name="Confirm-Diocese-User" element={<ConfirmDioceseUser/>} />
              <Route exact path="/parishUserConfirm/:token" name="Confirm-Parish-User" element={<ConfirmParishUser/>} />
              <Route exact path="/resetPassword/:id/:token" name="Reset-Password" element={<ResetAnyPassword/>} />
              <Route exact path="/forgotPassword" name="Forgot-Password" element={<ForgotPassword/>}/>
              <Route
                path="*"
                name="Home"
                element={
                  <ProtectedRoute>
                    <DefaultLayout />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Navigate replace to={this.setIndexPath()} />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </UserContext.Provider>
    );
  }
}

export default App;
