import React, { useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCardTitle,
    CFormFeedback,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CImage,
    CInputGroup,
    CInputGroupText,
    CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { toast } from "react-toastify";
import { cilLockLocked, cilUser } from "@coreui/icons";
import caritasLogo from "../../../assets/images/logo/caritas_logo_transparent.png";
import { resetPassword, sendEmailToResetPassword } from "src/data/authServises";

const ForgotPassword = () => {
   
    const [email, setEmail] = useState("");
   
    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1 className='mb-3'>Reset Password</h1>
                                        {/* <p className="text-medium-emphasis">
                                            Resetting PassWord
                                        </p> */}
                                       
                                        <CInputGroup className="mb-4">
                                            <CInputGroupText>
                                                <CIcon icon={cilLockLocked} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                name="email"
                                                placeholder="Enter your email"
                                                autoComplete="your email"
                                                required
                                            />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs={6}>
                                                <CButton color="info" className="px-4 bg-[#08f]" onClick={() => {
                                                    if (email === "") {
                                                        toast.error("Email must not be empty");
                                                    }
                                                    else {
                                                            sendEmailToResetPassword(email).then((res) => {
                                                                toast.success(res.data.message);
                                                                if (res.data.result === 'success') {
                                                                    toast.info("An email has been sent to you to recover your password.check it")
                                                                }
                                                            });
                                                       
                                                    }
                                                }

                                                }>
                                                   Send email
                                                </CButton>
                                            </CCol>
                                            <CCol xs={6} className="text-right">
                                                {/* <CButton color="link" className="px-0">
                              Forgot password?
                            </CButton> */}
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard
                                className="text-white py-5"
                                style={{ width: "44%", background: "#20a8d8" }}
                            >
                                <CCardBody className="text-center">
                                    <div>
                                        <CImage src={caritasLogo} width={90} height={90} />

                                        <CCardTitle className="h3">
                                            <strong>Caritas</strong>
                                            <div>
                                                <strong>Cyangugu-Gikongoro</strong>
                                            </div>
                                        </CCardTitle>
                                        <p className="h5">Project Management System</p>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
}

export default ForgotPassword