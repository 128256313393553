import { apiUrl } from "src/components/context/apiUrl";
import http from "./httpService";

const user = JSON.parse(localStorage.getItem("currentUserData"));

export function login(username, password) {
  return http.post(apiUrl + "/login/", { username, password });
}

export function changeUserPassword(id, oldPassword, newPassword) {
  return http.put(apiUrl + `/changeUserPassword/${id}/`, {
    oldPassword,
    newPassword,
  });
}
export function changeAnyUserPassword(id, newPassword) {
  return http.put(
    apiUrl + `/changeAnyUserPassword/${id}/`,
    { newPassword },
    {
      headers: {
        Authorization: "Token " + user.token,
      },
    }
  );
}

export function resetPassword(id, token, Password) {
  return http.put(apiUrl + `/resetAnyPassword/`, { id, token, Password }, {});
}
export function confirmDioceseUser(token) {
  return http.put(apiUrl + `/dioceseUserConfirm/${token}/`);
}

export function sendEmailToResetPassword(email) {
  return http.put(apiUrl + `/sendemailToresetPassword/`, { email });
}

export function confirmParishUser(token) {
  return http.put(apiUrl + `/parishUserConfirm/${token}/`);
}

export function logout() {
  localStorage.removeItem("currentUserData");
  return http.post(apiUrl + "/logout/");
}
